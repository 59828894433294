
<template>
    <v-app>
        <v-app-bar app :class="classMenu" :style="cssVars">
            <v-btn
                @click="goBack"
                :color="$vuetify.theme.contrast.color"
                icon
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer />
            <v-img
                max-width="90"
                :src="getLogo"
            />
            <v-spacer />
        </v-app-bar>

        <v-content>
            <v-container>
                <v-card-title class="justify-center">Pedido #{{ pedido.id }}</v-card-title>
                <v-chip v-if="pedido.status === 'cancelado'">Cancelado</v-chip>

                <h4 v-if="pedido.status === 'concluido'">Pedido finalizado</h4>
                <h4 v-else>Previsão para ficar pronto</h4>
                <h1>{{ horaFinalizacao }}</h1>

                <span>Acompanhe seu pedido</span>
                <!-- {{ pedido.agendado_datahora }}
                {{ pedido.total }}
                {{ pedido.status }} -->

                <v-stepper
                    v-model="step"
                    vertical
                    elevation="0"
                >
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                        Pedido Realizado
                        <small>{{ createdAt }}</small>
                    </v-stepper-step>
                    <v-stepper-content step="1"/>

                    <v-stepper-step
                        v-if="step >= 2"
                        :complete="step > 2"
                        step="2"
                    >
                        Em Preparação
                        <small>Entrou na fila de preparo às {{ preparacaoAt}}</small>
                    </v-stepper-step>
                    <v-stepper-content step="2"/>

                    <v-stepper-step
                        v-if="isDelivery && step >= 3"
                        :complete="step > 3"
                        step="3"
                    >
                        Saiu para Entrega
                        <small>Seu pedido acabou de sair para entrega</small>
                    </v-stepper-step>
                    <v-stepper-content v-if="isDelivery" step="3"/>

                    <v-stepper-step
                        v-if="!isDelivery"
                        :complete="step > 3"
                        step="3"
                    >
                        Aguardando Retirada
                    </v-stepper-step>
                    <v-stepper-content v-if="!isDelivery" step="3"/>

                    <v-stepper-step step="4" :complete="step === 4">
                        {{ step === 4 ? 'Finalizado' : `Ficará pronto entre ${horaFinalizacao}`}}
                    </v-stepper-step>
                    <v-stepper-content step="4"/>
                </v-stepper>
            </v-container>
            <Footer/>
        </v-content>
    </v-app>
</template>

<script>
import Footer from '@/components/Footer';
import { mapActions } from 'vuex';

export default {
    name: 'PedidoStatus',

    components: { Footer },

    data: () => ({
        step: 1,
        pedido: {},
        configuracoes: {},
    }),

    mounted() {
        this.getPedido();
        this.getConfiguracoes();
    },

    computed: {
        createdAt() {
            return this.moment(this.pedido.created_at).format('DD [de] MMMM HH:mm')
        },

        horaFinalizacao() {
            const intervalo = this.isDelivery
                ? this.configuracoes.tempo_entrega
                : this.configuracoes.tempo_retirada;

            if (!intervalo) {
                return '';
            }

            const [min, max] = intervalo.split(' - ');
            const diff = max - min;

            const prazoDe = this.moment(this.pedido.prazo_finalizacao)
                .subtract(diff, 'minutes')
                .format('HH:mm');

            const prazoAte = this.formatDate(this.pedido.prazo_finalizacao, 'HH:mm');

            return `${prazoDe} - ${prazoAte}`;
        },

        isDelivery() {
            return this.pedido.entrega === 2;
        },

        preparacaoAt() {
            const status = this.pedido.status_historico.find(e => e.status === 'em_preparacao');
            return this.formatDate(status.updated_at, 'HH:mm');
        },

        classMenu() {
            return this.$vuetify.theme.isDark ? 'app-bar-dark' : 'app-bar-light';
        },

        cssVars() {
            return {
                '--bg-color': this.$vuetify.theme.custom.appBarColor,
                'padding': '0 0 10px 0'
            };
        },

        getLogo() {
            const theme = this.$vuetify.theme.contrast.theme || 'dark';
            const logo = require(`@/assets/zapermenu-${theme}.png`);
            return logo;
        },
    },


    methods: {
        ...mapActions([
            'setAppData',
        ]),

        getPedido() {
            this.loading = true;
            this.$http.get(`pedido/${this.$route.params.pedUuid}`).then(resp => {
                console.log(resp.data);
                this.pedido = resp.data.data.pedido;
                this.setStep();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        setStep() {
            console.log(this.pedido.status)
            if (this.pedido.status === 'em_preparacao') {
                this.step = 2;
            }
            if (['aguardando_retirada', 'saiu_para_entrega'].includes(this.pedido.status)) {
                this.step = 3;
            }
            if (this.pedido.status === 'concluido') {
                this.step = 4;
            }
        },

        getConfiguracoes() {
            this.loading = true;
            this.$http.get('configuracoes').then(resp => {
                this.configuracoes = resp.data.data;
                this.setAppData({ configuracoes: this.configuracoes });
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        goBack() {
            this.$router.push(`/${this.$route.params.emp}`);
            location.reload();
        },
    },
}
</script>

<style scoped>
.logo {
    font-family: fantasy;
    color: red;
    font-size: 22px;
    /* font-weight: 900; */
}

.app-bar-dark {
    background-color: var(--bg-color) !important;
}

.app-bar-light {
    background-color: var(--bg-color) !important;
}
</style>
